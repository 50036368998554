import {StatusBar} from 'expo-status-bar';
import {Image, SafeAreaView, StyleSheet, Text, View} from 'react-native';
import {Appbar, Surface, TextInput, Button, FAB} from 'react-native-paper'
import DropDown from "react-native-paper-dropdown";
import {useState} from "react";
import {deflate} from "pako";
import {fromUint8Array} from "js-base64";
import WebView from "../webview";
import {downloadImage} from "../utils";

export default function App() {
    const [showDropDown, setShowDropDown] = useState(false);
    const chartTypes = [
        {
            label: "Flowchart",
            value: "Flowchart",
        },
        {
            label: "Sequence diagram",
            value: "Sequence diagram"
        },
        {
            label: "Gantt chart",
            value: "Gantt chart",
        },
        {
            label: "Class diagram",
            value: "Class diagram",
        },
        {
            label: "State diagram",
            value: "State diagram",
        },
        {
            label: "Pie chart",
            value: "Pie chart",
        },
        {
            label: "Bar chart",
            value: "Bar chart"
        },
        {
            label: "User Journey diagram",
            value: "User Journey diagram"
        }
    ];
    const [chartType, setChatType] = useState("Flowchart");
    const [description, setDescription] = useState("");
    const [code, setCode] = useState('');
    const html = `
    <html>
      <body>
        <pre class="mermaid">
                ${code}
        </pre>
        <script type="module">
          import mermaid from 'https://cdn.jsdelivr.net/npm/mermaid@10/dist/mermaid.esm.min.mjs';
          mermaid.initialize({ startOnLoad: true });
        </script>
      </body>
    </html>
    `
    const [loading, setLoading] = useState(false)
    const formatJSON = (data) => JSON.stringify(data, undefined, 2);
    const serialize = (state) => {
        const data = new TextEncoder().encode(state);
        const compressed = deflate(data, {level: 9}); // zlib level 9
        return fromUint8Array(compressed, true); // url safe base64 encoding
    }
    return (
        <View style={styles.containerStyle}>
            <Appbar.Header>
                <Appbar.Content title="Ai Diagram generator"/>
            </Appbar.Header>
            <View style={{
                flexDirection: "row"
            }}>
                <Surface style={styles.safeContainerStyle}>
                    <DropDown
                        label={"chart type"}
                        mode={"outlined"}
                        visible={showDropDown}
                        showDropDown={() => setShowDropDown(true)}
                        onDismiss={() => setShowDropDown(false)}
                        value={chartType}
                        setValue={setChatType}
                        list={chartTypes}
                    />
                    <TextInput
                        multiline
                        value={description}
                        onChangeText={text => setDescription(text)}
                        mode="outlined"
                        label="Description"
                        numberOfLines={20}
                        style={{marginVertical: 20, minHeight: 100}}
                    />
                    <Button loading={loading} mode="contained" onPress={async () => {
                        try {
                            setLoading(true)
                            const content = `give mermaid version 10.6.1 code to use https://mermaid.live only without any Explanation for ${chartType} with this description ${description}`;
                            let response = await fetch('https://gpti.projectsrpp.repl.co/api/gpti', {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify({
                                    "prompt": content,
                                    "model": 1,
                                    "type": "json"
                                })
                            });
                            const aiResponse = await response.json();
                            let mycode = "";
                            console.log("aiResponse.gpt", aiResponse.gpt)
                            mycode = aiResponse?.gpt.includes("```") ?  aiResponse.gpt?.split("```")[1].replaceAll("mermaid", "") : aiResponse.gpt?.replaceAll("mermaid", "");

                            console.log("code", mycode)
                            setCode(mycode);
                            setLoading(false);
                        } catch (e) {
                            setLoading(false);
                        }

                    }}>
                        Generate
                    </Button>
                </Surface>
                <Surface style={{
                    flex: 1,
                    margin: 20,
                    padding: 20,
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    {
                        code && <WebView
                            style={{
                                flex: 1
                            }}
                            originWhitelist={['*']}
                            source={{html: html}}
                        />
                    }
                    {
                        code && <FAB
                        icon="file-png-box"
                        style={styles.fab}
                        onPress={async () => {
                                const defaultState = {
                                    code: code,
                                    mermaid: formatJSON({
                                        theme: 'default'
                                    }),
                                    autoSync: true,
                                    updateDiagram: true
                                };

                                const json = JSON.stringify(defaultState);
                                console.log(json)
                                const serialized = serialize(json);
                                console.log(`https://mermaid.ink/img/pako:${serialized}`);
                                const url = `https://mermaid.ink/img/pako:${serialized}`;
                                await downloadImage(url);
                            }}
                        />
                    }
                </Surface>
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    containerStyle: {
        flex: 1,
    },
    safeContainerStyle: {
        flex: 1,
        padding: 20,
        margin: 20,
        maxWidth: 600
    },
    fab: {
        position: 'absolute',
        margin: 16,
        right: 0,
        bottom: 0,
    },
});
